<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
      </div>
      <div class="pageFucView_right">
        <el-form style="display: flex" label-width="80px" label-position="left">
          <el-form-item label="商品名称:" style="margin-top: 0">
            <el-input v-model="search.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="商品分类:" style="margin-top: 0">
            <el-select
                v-model="search.category_id"
                clearable
                placeholder="请选择"
            >
              <el-option
                  v-for="item in goodsCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="商品品牌:">
            <el-select
                v-model="search.brand_id"
                clearable
                placeholder="请选择"
            >
              <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetHandle">重置</el-button>
        </el-form>
      </div>
    </div>
    <el-button type="text" @click="handleTrashLot">批量还原</el-button>
    <el-table
        :data="list"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="商品编号"
          width="80">
      </el-table-column>
      <el-table-column
          prop="name"
          label="商品"
      >
        <template #default="{ row }">
          <div class="goodsInfoView">
            <el-image :src="row.pic" class="goodsPic" />
            <span class="goodsName">{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="price"
          label="售价"
          width="70">
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template #default="{ row }">
<!--          <el-button type="text" @click="handleDetail(row)">查看</el-button>-->
          <el-button type="text" @click="handleTrash(row.id)">还原</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="search['per-page']" :current-page.sync="search.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>
<script>
import {apiGetGoodsList, apiGoodsTrash} from '@/request/api'
export default {
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  },
  data() {
    return {
      list: [],
      search:{},
      pageCount: 0,
      brandList:[],
      goodsCategoryList:[],
      multipleSelection: []
    }
  },
  created() {
    this.initSearch()
    this.getData()
    this.getOption();
  },
  methods: {
    initSearch() {
      this.search = {
        name: '',
        type: 'all',
        is_del:1,
        category_id: '',
        brand_id: '',
        page: 1,
        'per-page': 10,
      }
    },
    getData() {
      apiGetGoodsList(this.search).then(res => {
        if(res.code == 200){
          this.list = res.data.list
          this.pageCount = res.data.count
        }
      })
    },
    getOption(){
      apiGetGoodsOptions().then(res => {
        if(res.code == 200){
          this.brandList = res.data.brandCate;
          this.goodsCategoryList = res.data.goodsCate;
        }
      })
    },
    resetHandle() {
      this.initSearch()
      this.getData()
    },
    searchHandle() {
      this.search.page = 1;
      this.search.type = 'all'
      this.getData()
    },
    pageChangeHandle(){
      this.getData()
      window.screenTop = 0;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id)
    },
    handleTrashLot(){
      var ids = this.multipleSelection.map(item => item.id)
      this.trashHandle(ids)
    },
    handleTrash(id){
      var ids = [id]
      this.trashHandle(ids)
    },
    trashHandle(ids) {
      this.$confirm('是否还原该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        apiGoodsTrash({ids}).then(res => {
          if(res.code == 200){
            this.$message.success(res.message)
            this.search.page = 1;
            this.getData()
          }
        })
      })
    },
  }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
.goodsInfoView {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}
.goodsInfoView .goodsPic {
  width: 45px;height: 45px;border-radius: 10px;margin-right: 10px
}
.goodsInfoView .goodsName {
  max-height: 50px;
  width: calc(100% - 45px - 10px);
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
